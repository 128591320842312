import {
  Container,
  Drawer,
  Grid,
  Hidden,
  List,
  ListItem,
  Typography,
  Box,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { CancelIcon } from 'assets/icons/cancel';
import { UndoIcon } from 'assets/icons/undo';
import Analytics from 'utils/AnalyticsHelper';
import { useShowHubLink } from 'common/hooks/useShowHubLink';
import React, { useState, useEffect, useCallback } from 'react';
// import { useShowRituals } from 'common/hooks/useShowRituals';
import { useHistory, useLocation } from 'react-router-dom';
import { AppName, QuickNav } from '@groov/ui';
import { colors } from 'styles/colors';
import { emailHashHelper } from 'utils/EmailHashHelper';
import mentemiaLogo from '../../../assets/images/mentemia-logo.png';
import mentemiaLogo2x from '../../../assets/images/mentemia-logo@2x.png';
import mentemiaLogo3x from '../../../assets/images/mentemia-logo@3x.png';
import { HeaderPrompts } from '../../../prompts/prompts';
import { RoutePath, RouteLabel } from '../../../types/routes';
import { DefaultButton } from '../Button';
import { Link } from '../Link';
import {
  HeaderLinks,
  ContextualNudge,
  IntegrationLink,
  HEADER_ANALYTICS_EVENT,
  HEADER_ANALYTICS_SEGMENT,
} from './constants';
import { useAppDispatch, useAppSelector } from 'store';
import { HeaderTestId } from 'test/constants/HeaderTestId';
import './Header.css';
import { useFeatureFlags } from 'FeatureFlagContext';
import { useHeaderLinks } from './Header.hooks';
import { logoutAction } from 'auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: theme.spacing(6),
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      height: theme.spacing(32),
      alignItems: 'center',
      paddingBottom: theme.spacing(5),
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    logoContainerMob: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    headerMenu: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    headerMenuMobile: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    headerItem: {
      marginLeft: theme.spacing(4),
      minHeight: '2rem',
    },
    routeText: {
      color: colors.slateGrey,
      fontSize: 16,
      marginBottom: 4,
      fontWeight: 'bold',
      height: 20,
      letterSpacing: 0,
      textWrap: 'nowrap',
    },
    activeRouteText: {
      color: theme.palette.primary.main,
    },
    selected: {
      display: 'flex',
      flexDirection: 'column',
    },
    underline: {
      height: 2,
      marginTop: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      borderRadius: 1,
    },
    drawer: {
      width: '100vw',
    },
    mobileMenu: {
      width: '100vw',
    },
    mobileMenuHeader: {
      padding: theme.spacing(4),
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(9),
    },
    mobileClose: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    mobileMenuContent: {
      marginTop: theme.spacing(4),
    },
    mobileMenuListItem: {
      height: theme.spacing(12),
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-start',
      padding: 0,
    },
    mobileMenuActive: {
      width: theme.spacing(2),
      height: theme.spacing(12),
      backgroundColor: colors.blue,
    },
    mobileMenuText: {
      marginLeft: theme.spacing(4),
    },
    menu: {
      marginTop: 15,
    },
    orgName: {
      color: theme.palette.text.disabled,
      marginLeft: theme.spacing(4.5),
    },
  })
);

export const Header: React.FC = (props) => {
  const classes = useStyles(props);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const [modalOpen, setShowModal] = useState(false);
  const showHubBeta = useShowHubLink();
  const showRituals = false; // useShowRituals();
  const orgName = useAppSelector((state) => state.appState.orgSettings.orgName);
  const { orgId, accessToken } = useAppSelector((state) => state.auth);
  const { useNewAppHeader, groovAnywhereIntegrations, enableContextualNudge } = useFeatureFlags();

  const contextualNudgeLink = enableContextualNudge ? ContextualNudge : {};
  const integrationLink = groovAnywhereIntegrations ? IntegrationLink : {};

  const allLinks = { ...HeaderLinks, ...contextualNudgeLink, ...integrationLink };
  const links = useHeaderLinks(allLinks);

  const fetchTabNameFromPath = useCallback(() => {
    let tabName = null;
    for (const link of Object.values(links)) {
      if (pathname === link.route) {
        tabName = link.name;
        break;
      }
    }
    return tabName;
  }, [pathname, links]);

  useEffect(() => {
    const tabName = fetchTabNameFromPath();
    tabName && Analytics.trackEvent(HEADER_ANALYTICS_EVENT, HEADER_ANALYTICS_SEGMENT, tabName);
  }, [pathname, fetchTabNameFromPath]);

  const signOut = () => {
    dispatch(logoutAction());
    emailHashHelper.deleteEmailHash();
    Analytics.trackEvent('SignOutClicked');
  };

  const handleNavItemClick = (appName: AppName) => {
    Analytics.trackEventV2('QuickNavItemClicked', {
      source: AppName.AdminPortal.toString(),
      destination: appName.toString(),
    });
  };

  const handleNavMenuClick = () => {
    Analytics.trackEvent('QuickNavMenuClicked');
  };

  const QuickNavComponent = () => {
    return (
      <QuickNav
        accessToken={accessToken}
        encryptionKey={process.env.REACT_APP_TOKEN_EXCHANGE_ENCRYPTION_KEY}
        onSignOutClick={signOut}
        organisationId={orgId}
        onNavItemClick={handleNavItemClick}
        onMenuClick={handleNavMenuClick}
      />
    );
  };

  const MobileHeaderQuickNav = () => {
    return useNewAppHeader ? (
      <Box style={{ marginLeft: 32, marginRight: 10, paddingTop: 4 }}>
        <QuickNavComponent />
      </Box>
    ) : null;
  };

  const AppHeaderSignOutButtonOrQuickNav = () => {
    return useNewAppHeader ? (
      <Box style={{ marginLeft: 32, marginRight: 10, paddingBottom: 4 }}>
        <QuickNavComponent />
      </Box>
    ) : (
      <DefaultButton
        color="primary"
        className={classes.headerItem}
        onClick={signOut}
        data-testid="logout_button">
        {HeaderPrompts.signOutButtonText}
      </DefaultButton>
    );
  };

  const renderMobileMenu = () => (
    <Grid className={classes.headerMenuMobile}>
      <MenuIcon onClick={() => setShowModal(!modalOpen)} data-testid="mobile_drawer_open_button" />
      <MobileHeaderQuickNav />
      <React.Fragment key={'left'}>
        <Drawer
          classes={{ paper: classes.drawer }}
          anchor={'left'}
          open={modalOpen}
          onClose={() => setShowModal(false)}>
          <Grid
            className={classes.mobileMenu}
            role="presentation"
            onClick={() => setShowModal(false)}
            onKeyDown={() => setShowModal(false)}>
            <Grid container className={classes.mobileMenuHeader}>
              <Grid item xs={11} className={classes.logoContainerMob}>
                <img
                  src={mentemiaLogo}
                  srcSet={`${mentemiaLogo2x} 2x, ${mentemiaLogo3x} 3x`}
                  alt="groov"
                />
                <Typography
                  component="h5"
                  variant="h4"
                  className={classes.orgName}
                  data-testid={HeaderTestId.orgName}>
                  {orgName}
                </Typography>
              </Grid>
              <Grid item xs={1} className={classes.mobileClose}>
                <Link
                  style={{ padding: 0, minWidth: 'auto' }}
                  onClick={() => setShowModal(false)}
                  data-testid="mobile_drawer_close_button">
                  <CancelIcon />
                </Link>
              </Grid>
            </Grid>
            <Grid container className={classes.mobileMenuContent}>
              <List disablePadding>
                {Object.values(links).map((link) => {
                  return (
                    ((link.name === RouteLabel.Ritual && showRituals) ||
                      (link.name === RouteLabel.HubBeta && showHubBeta) ||
                      (link.name !== RouteLabel.Ritual && link.name !== RouteLabel.HubBeta)) &&
                    renderMobileMenuItem(
                      link.id,
                      link.name,
                      link.name === RouteLabel.Ritual
                        ? pathname.indexOf(RoutePath.ManageRituals) !== -1 ||
                            pathname.indexOf(RoutePath.CreateCompany) !== -1 ||
                            pathname.indexOf(
                              RoutePath.EditCompany.substring(
                                0,
                                RoutePath.EditCompany.indexOf(':id')
                              )
                            ) !== -1
                        : pathname.indexOf(link.route) !== -1,
                      link.route,
                      link.icon
                    )
                  );
                })}
                {renderMobileMenuItem(
                  'logout',
                  HeaderPrompts.signOutButtonText,
                  false,
                  RoutePath.Login,
                  UndoIcon
                )}
              </List>
            </Grid>
          </Grid>
        </Drawer>
      </React.Fragment>
    </Grid>
  );

  const renderMobileMenuItem = (
    id: string,
    label: string,
    isActive: boolean,
    route: string,
    Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  ) => (
    <ListItem
      key={label}
      button
      className={classes.mobileMenuListItem}
      onClick={() => {
        label === HeaderPrompts.signOutButtonText ? signOut() : history.replace(route);
      }}
      data-testid={id + '_' + (id === 'logout' ? 'button' : 'tab')}>
      {isActive && <div className={classes.mobileMenuActive} />}
      <div style={{ marginLeft: isActive ? 40 : 48 }}>
        <Icon color={isActive ? colors.blue : colors.slateGrey} />
      </div>
      <Typography
        component="h2"
        align="center"
        className={`${classes.mobileMenuText} ${classes.routeText} ${isActive ? classes.activeRouteText : ''}`}>
        {label}
      </Typography>
    </ListItem>
  );

  const renderMenu = () => (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid container className={classes.headerMenu}>
        {Object.values(links).map((link) => {
          return (
            ((link.name === RouteLabel.Ritual && showRituals) ||
              (link.name === RouteLabel.HubBeta && showHubBeta) ||
              (link.name !== RouteLabel.Ritual && link.name !== RouteLabel.HubBeta)) && (
              <Link
                onClick={() => history.replace(link.route)}
                key={link.id}
                data-testid={link.id + '_tab'}>
                {renderMenuItem(
                  link.name,
                  link.name === RouteLabel.Ritual
                    ? pathname.indexOf(RoutePath.ManageRituals) !== -1 ||
                        pathname.indexOf(RoutePath.CreateCompany) !== -1 ||
                        pathname.indexOf(
                          RoutePath.EditCompany.substring(0, RoutePath.EditCompany.indexOf(':id'))
                        ) !== -1
                    : pathname.indexOf(link.route) !== -1
                )}
              </Link>
            )
          );
        })}
      </Grid>
      <AppHeaderSignOutButtonOrQuickNav />
    </Grid>
  );

  const renderMenuItem = (label: string, isActive: boolean) => (
    <div className={`${classes.selected} ${classes.headerItem}`}>
      <Typography
        component="h2"
        align="center"
        className={`${classes.routeText} ${isActive ? classes.activeRouteText : ''}`}>
        {label}
      </Typography>
      <div className={isActive ? classes.underline : ''} />
    </div>
  );

  return (
    <Container maxWidth={false}>
      <Grid className={classes.headerContainer}>
        <Grid>
          <Grid className={classes.logoContainer}>
            <img
              src={mentemiaLogo}
              srcSet={`${mentemiaLogo2x} 2x, ${mentemiaLogo3x} 3x`}
              alt="groov"
            />
          </Grid>
          <Typography
            component="h5"
            variant="h4"
            className={classes.orgName}
            data-testid="org_name">
            {orgName}
          </Typography>
        </Grid>
        <div className={classes.menu}>
          <Hidden smDown>{renderMenu()}</Hidden>
          <Hidden mdUp>{renderMobileMenu()}</Hidden>
        </div>
      </Grid>
    </Container>
  );
};
