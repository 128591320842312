import React from 'react';
import { styled } from '@material-ui/core/styles';
import MaterialCard from '@material-ui/core/Card';
import { colors } from 'styles/colors';
import { Box, Grid, Typography } from '@material-ui/core';
import { PromptItemType } from '../../GroovVoice';
import { PromptTags } from '../Common/PromptTags';
import { useHistory } from 'react-router-dom';
import { RoutePath } from 'types/routes';
import { AnalyticsInfo } from '../Common/AnalyticsInfo';
import { format } from 'date-fns';
import { getAndSetCurrentSelectedPromptDetails, getPromptTags } from '../../GroovVoice.utils';

const Card = styled(MaterialCard)(({ theme }) => ({
  padding: theme.spacing(6, 8),
  margin: theme.spacing(4, 2),
  borderRadius: theme.spacing(3),
  background: colors.white,
  fontWeight: 'bold',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.grey[400],
  '&:hover': {},
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

export function PromptItem({ prompt }: { prompt: PromptItemType }) {
  const { push } = useHistory();
  const handleCardPress = async () => {
    await getAndSetCurrentSelectedPromptDetails(prompt.promptId);
    push(`${RoutePath.GroovVoice}/${prompt.promptId}`);
  };

  const responseRate = prompt.responseRate * 100;

  const formattedRate = Number.isInteger(responseRate)
    ? responseRate.toString()
    : responseRate.toFixed(1);

  return (
    <Card elevation={1} onClick={handleCardPress}>
      <Box px={4}>
        <PromptTags tags={getPromptTags(prompt)} />
        <Box>
          <Grid container>
            <Grid item xs={8}>
              <Box py={4}>
                <Typography variant="h4">{prompt.promptName}</Typography>
                <Typography variant="body2">
                  {format(new Date(prompt.lastSentUtc), 'yyyy-MM-dd hh:mm a')}
                </Typography>
                <Box pt={4}>
                  <Typography variant="body1">{prompt.promptId}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={6} justifyContent="center" style={{ display: 'flex' }}>
                  <AnalyticsInfo label="Rate" value={formattedRate} subValue="%" />
                </Grid>
                <Grid item xs={6} justifyContent="center" style={{ display: 'flex' }}>
                  <AnalyticsInfo
                    label="Responses"
                    value={`${prompt.totalResponses}`}
                    subValue={`/${prompt.totalSent}`}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
}
