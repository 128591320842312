import { AxiosRequestConfig } from 'axios';
import { ApiRequest } from 'services/request';
import { dispatch } from 'store';
import { setCurrentSelectedPromptDetails } from './GroovVoice.slice';
import { PromptItemType } from './GroovVoice.d';
import moment from 'moment';

export const colorPalette = [
  '#006AFF',
  '#74E4C6',
  '#8C6B8C',
  '#7558FF',
  '#B55D35',
  '#D95A96',
  '#F0CEE2',
  '#0D4027',
  '#FBAA23',
  '#EE3130',
  '#6EBFE2',
];

const BASE_SERVICE_URL = process.env.REACT_APP_SERVICE_URL;

export const getRandomColor = (index?: number): string => {
  if (index?.toString()) {
    return colorPalette[index % colorPalette.length];
  }
  return colorPalette[Math.floor(Math.random() * colorPalette.length)];
};

export const getGroovVoicePrompts = async (
  startTime: moment.Moment = getFirstDayOfMonth(),
  endTime: moment.Moment = getEndOfCurrentDay()
) => {
  const format = 'YYYY-MM-DDTHH:mm:ss';
  const startTimeUtc = startTime.utc().format(format);
  const endTimeUtc = endTime.utc().format(format);
  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: `conversation/partner/prompts/report?startTimeUtc=${startTimeUtc}&endTimeUtc=${endTimeUtc}`,
    method: 'GET',
  };

  const result = await ApiRequest(config);
  return result;
};

export const getFirstDayOfMonth = () => {
  return moment().startOf('month');
};

export const getEndOfCurrentDay = () => {
  return moment().endOf('day');
};

export const getAndSetCurrentSelectedPromptDetails = async (id: string) => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    // We need to set the time range to 1970-01-01 to 2170-01-01 until the backend is fixed
    url: `conversation/partner/prompts/report/${id}?startTimeUtc=1970-01-01&endTimeUtc=2170-01-01`,
    method: 'GET',
  };

  const result = await ApiRequest(config);
  if (result.status === 200) {
    dispatch(setCurrentSelectedPromptDetails(result.data));
  }
};

export const getUniqueTags = (prompts: PromptItemType[]): string[] => {
  const allTags = prompts?.flatMap((prompt) => prompt.tags);
  return Array.from(new Set(allTags));
};

export const getPromptTags = (prompt: PromptItemType) => {
  const typeMap = [
    { id: 'action_buttons', label: 'Action Buttons' },
    { id: '6pt_scale', label: '6pt Scale' },
    { id: 'open_text', label: 'Open Text' },
    { id: 'action_w_open_text', label: 'Action Buttons with Open Text' },
  ];
  const typeTag = typeMap.filter((type) => type.id === prompt?.properties?.prompt_cta_type)?.[0];

  const otherTags = prompt?.tags.map((p) => ({ id: p, label: p })) ?? [];

  if (typeTag) {
    return [typeTag, ...otherTags];
  }
  return otherTags;
};

type SixPtScaleCounts = {
  stronglyAgreeCount: number;
  agreeCount: number;
  neutralCount: number;
  disagreeCount: number;
  stronglyDisagreeCount: number;
  notApplicableCount: number;
};

// The six-point scale mean is a weighted average of the five-point scale.
// 1 = strongly agree, 2 = agree, 3 = neutral, 4 = disagree, 5 = strongly disagree
export const sixPtMean = ({
  stronglyAgreeCount,
  agreeCount,
  neutralCount,
  disagreeCount,
  stronglyDisagreeCount,
}: SixPtScaleCounts) => {
  const total =
    stronglyAgreeCount + agreeCount + neutralCount + disagreeCount + stronglyDisagreeCount;

  if (total === 0) {
    return 0;
  }

  return (
    (stronglyAgreeCount * 5 +
      agreeCount * 4 +
      neutralCount * 3 +
      disagreeCount * 2 +
      stronglyDisagreeCount * 1) /
    total
  );
};

// We use a Net Promoter Score for favourability which discounts Neutral responses.
export const sixPtNetFavourability = ({
  stronglyAgreeCount,
  agreeCount,
  disagreeCount,
  stronglyDisagreeCount,
}: SixPtScaleCounts) => {
  const netFavourablity = stronglyAgreeCount + agreeCount;
  const netUnfavourability = stronglyDisagreeCount + disagreeCount;

  const netPromoterTotal = netFavourablity + netUnfavourability;

  const favourabilityPercentage = netPromoterTotal ? (netFavourablity / netPromoterTotal) * 100 : 0;
  const unfavourabilityPercentage = netPromoterTotal
    ? (netUnfavourability / netPromoterTotal) * 100
    : 0;

  return { favourabilityPercentage, unfavourabilityPercentage };
};

export const formatNumber = (value: number) => {
  return value % 1 === 0 ? value.toString() : value.toFixed(1);
};
