import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, makeStyles, Typography, Button, Grid } from '@material-ui/core';
import { PromptTags } from '../Common/PromptTags';
import history from 'utils/history';
import { format } from 'date-fns';
import Markdown from 'markdown-to-jsx';
import { AnalyticsInfo } from '../Common/AnalyticsInfo';
import { PromptDetails, PromptItemType } from '../../GroovVoice.d';
import {
  getPromptTags,
  formatNumber,
  sixPtMean,
  sixPtNetFavourability,
} from 'pages/GroovVoice/GroovVoice.utils';

export function PromptInformation({
  prompt,
  promptDetails,
}: {
  prompt: PromptItemType;
  promptDetails?: PromptDetails;
}) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const promptBodyRef = useRef<HTMLDivElement>(null);

  const { favourabilityPercentage, unfavourabilityPercentage, mean } = useMemo(() => {
    const getCountByText = (text: string) => {
      return (promptDetails?.count ?? []).filter((c) => c.text === text).length;
    };

    const stronglyAgreeCount = getCountByText('Strongly agree');
    const agreeCount = getCountByText('Agree');
    const neutralCount = getCountByText('Neither agree nor disagree');
    const disagreeCount = getCountByText('Disagree');
    const stronglyDisagreeCount = getCountByText('Strongly disagree');
    const notApplicableCount = getCountByText('Not applicable');

    const mean = sixPtMean({
      stronglyAgreeCount,
      agreeCount,
      neutralCount,
      disagreeCount,
      stronglyDisagreeCount,
      notApplicableCount,
    });

    const { favourabilityPercentage, unfavourabilityPercentage } = sixPtNetFavourability({
      stronglyAgreeCount,
      agreeCount,
      neutralCount,
      disagreeCount,
      stronglyDisagreeCount,
      notApplicableCount,
    });

    return { favourabilityPercentage, unfavourabilityPercentage, mean };
  }, [promptDetails?.count]);

  useEffect(() => {
    const checkOverflow = () => {
      const element = promptBodyRef.current;
      if (element) {
        setIsOverflowing(element.scrollHeight > element.clientHeight);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  // We calculate the response rate rather than use it from prompt
  // because it could have changed by the time we get here.
  const { responseCount, responseRate } = useMemo(() => {
    const responseCount = promptDetails?.responses.length ?? 0;

    return {
      responseCount,
      responseRate: prompt.totalSent ? (responseCount / prompt.totalSent) * 100 : 0,
    };
  }, [prompt.totalSent, promptDetails?.responses.length]);

  return (
    <Box>
      <Grid direction="row" container alignItems="center">
        <Button variant="text" size="small" onClick={handleGoBack}>
          <Typography className={classes.promptBreadcrumbsLink}>Prompts</Typography>
        </Button>
        <Typography className={classes.promptBreadcrumbsText}>/ {prompt.promptName}</Typography>
      </Grid>
      <Box mt={8}>
        <PromptTags tags={getPromptTags(prompt)} />
        <Typography variant="h2" className={classes.spacing}>
          {prompt.promptName}
        </Typography>
        <Typography className={classes.promptId}>{prompt.promptId}</Typography>
        {prompt.lastSentUtc && (
          <Typography variant="body2" className={classes.promptDateTime}>
            <span className={classes.boldText}>Sent</span>
            {format(new Date(prompt.lastSentUtc), 'yyyy-MM-dd hh:mm a')}
          </Typography>
        )}
        <Typography className={classes.boldLabel}>Prompt</Typography>
        <div
          ref={promptBodyRef}
          className={`${classes.promptBody} ${expanded ? classes.expanded : ''}`}>
          <Typography>
            <Markdown>{prompt.promptText}</Markdown>
          </Typography>
        </div>
        {isOverflowing && (
          <Button onClick={handleExpandClick} className={classes.expandButton}>
            {expanded ? 'See less' : 'See more'}
          </Button>
        )}
      </Box>
      <Box mt={4} className={classes.analyticsInfoContainer}>
        <AnalyticsInfo label="Rate" value={formatNumber(responseRate)} subValue="%" />
        <AnalyticsInfo
          label="Responses"
          value={formatNumber(responseCount)}
          subValue={`/${formatNumber(prompt.totalSent)}`}
        />
        {prompt?.properties?.prompt_cta_type === '6pt_scale' && (
          <>
            <AnalyticsInfo label="Mean" value={formatNumber(mean)} />
            <AnalyticsInfo
              label="Favourable"
              value={formatNumber(favourabilityPercentage)}
              subValue="%"
            />
            <AnalyticsInfo
              label="Unfavourable"
              value={formatNumber(unfavourabilityPercentage)}
              subValue="%"
            />
          </>
        )}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  promptBreadcrumbsText: {
    textTransform: 'none',
    color: '#333',
  },
  promptBreadcrumbsLink: {
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
  promptId: {
    marginBottom: 16,
  },
  promptDateTime: {
    fontSize: 16,
    marginBottom: 16,
    fontWeight: 400,
    color: 'black',
  },
  boldText: {
    fontWeight: 'bolder',
    paddingRight: 16,
  },
  boldLabel: {
    fontSize: 16,
    marginBottom: 16,
    color: 'black',
    fontWeight: 'bolder',
  },
  promptBody: {
    minHeight: '50px',
    maxHeight: '160px',
    overflowY: 'hidden',
    position: 'relative',
    color: 'black',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '32px',
      background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
    },
  },
  expanded: {
    maxHeight: 'none',
    overflowY: 'visible',
    '&::after': {
      content: 'none',
    },
  },
  expandButton: {
    display: 'block',
    marginTop: theme.spacing(2),
    textTransform: 'none',
    color: 'black',
    textAlign: 'left',
    padding: 0,
  },
  analyticsInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  spacing: {
    marginTop: 12,
  },
}));
